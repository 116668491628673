

/* section status */
export enum Status {
  NotRequired = 'not_required',
  Required = 'required',
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export const TranslatedStatus = {
  NotRequired: $localize`:@@uploadDocuments.NotRequiredStatus:not_required`,
  Required: $localize`:@@uploadDocuments.RequiredStatus:required`,
  Approved: $localize`:@@uploadDocuments.ApprovedStatus:approved`,
  Pending: $localize`:@@uploadDocuments.PendingStatus:pending`,
  Rejected: $localize`:@@uploadDocuments.RejectedStatus:rejected`,
}

/* section type */
export enum Type {
  ID = 'i',
  Passport = 'e',
  PhotoWithId = 'h',
  PaymentMethod = 'm',
  AddressProof = 'p',
  SourceOfFunds = 's',
  Other = 'o',
}

export const typeMapping = {
  'i': Type.ID,
  'e': Type.Passport,
  'h': Type.PhotoWithId,
  'm': Type.PaymentMethod,
  's': Type.SourceOfFunds,
  'p': Type.AddressProof,
  'o': Type.Other,
};

/* single or standalone doc */
export interface Document extends UserDocument {
  description?: string;
}

export interface InputFile {
  index: number,
  barValue: number,
  description?: string;
  displayLoadBar: boolean,

  type: string;
  file?: File,
}

/* document section */
export interface DocumentSection {
  title: string;
  description: { full: string, short?: string };
  icon: string;
  attempt: number;

  status?: Status | string;
  type?: string;
  hide?: boolean;
  disable?: boolean;
  isExpanded: boolean;

  documents?: Document[];
  files: InputFile[];
}

/* whole Data */
export interface DocumentData {
  sections: DocumentSection[];
}

export interface UserDocument {
  name?: string, 
  created?: string, 
  status?: Status | string, 
  status_display?: string,
}

export interface UserDocumentSection {
  [key: string]: UserDocument[],
}

export interface UserDocumentIsHide {
  [key: string]: boolean;
}

export interface UserDocumentSectionStatus {
  [key: string]: Status;
}

export interface UserDocumentsResponse {
  documents?: UserDocumentSection,
  hide_not_required?: UserDocumentIsHide,
  hide_tab_content?: boolean,
  status?: UserDocumentSectionStatus,
  user_country?: string,
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

export const test_userDocumentsResponce: UserDocumentsResponse = {
  documents:
    { 
/*       'i': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_passport_img.png",
        status: Status.Required,
        status_display: "Required",
      }
    ], */
    'e': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_passport_img.png",
        status: Status.Rejected,
        status_display: "Rejected",
      }
    ],
    'h': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_foto.png",
        status: Status.Approved,
        status_display: "Approved",
      }
    ],
    'm': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_foto1.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_foto2.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_foto3.png",
        status: Status.Pending,
        status_display: "Pending",
      }
    ],
    's': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_img.png",
        status: Status.Rejected,
        status_display: "Rejected",
      }
    ],
    'o': [
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "some_foto.png",
        status: Status.Pending,
        status_display: "Pending",
      }
    ]
  },

  hide_not_required: { 'i': false, 'e': false, 'h': false, 'm': false, 's': false, 'o': false },
  status: { 'i': Status.Required, 'e': Status.Rejected, 'h': Status.Approved, 'm': Status.Pending, 's': Status.Rejected, 'o': Status.Pending },

  hide_tab_content: false,
  user_country: "PL"
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

export const documentData: DocumentData = {
  sections: [
    {
      title: $localize`:@@uploadDocuments.IDLabel:ID`,
      description: { full: $localize`:@@uploadDocuments.idDescription:Make sure the photo is sharp and all four corners of the document are visible`, short: 'Some text'},
      type: Type.ID,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.ID, description: $localize`:@@uploadDocuments.twinFirstDescriptionList:Please upload front side of the document`, file: null },
        { index: 1, displayLoadBar: false, barValue: 10, type: Type.ID, description: $localize`:@@uploadDocuments.twinSecondDescriptionList:Please upload the back side of the document`, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.passportLabel:Passport`,
      description: { full: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible`, short: "Some text"},
      type: Type.Passport,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.Passport, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.photoIdLabel:Photo with holding ID`,
      description: { full: $localize`:@@uploadDocuments.selfIDescription:Send a selfie that includes a photo of your figure from the waist up and holding an identification document in your hand, so that the photo and the data on the document are visible and legible`, short: 'Some text'},
      type: Type.PhotoWithId,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.PhotoWithId, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.paymentMethodLabel:Payment Method`,
      description: { full: $localize`:@@uploadDocuments.paymentDescription:Send a document confirming the payment to our service. This can be a transfer confirmation, a photo of the payment card in case of a card payment, or a bank statement with the visible payment`, short: 'Some text' },
      type: Type.PaymentMethod,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.PaymentMethod, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.addressLabel:Proof of Address`,
      description: { full: $localize`:@@uploadDocuments.addressDescription:A bank confirmation of the last payment to our service with the visible address and your first and last name, a utility bill - water, electricity, gas, council tax, a bank statement containing address details and your first and last name`, short: 'Some text' },
      type: Type.AddressProof,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.AddressProof, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.sofLabel:Source of Funds`,
      description: { full: $localize`:@@uploadDocuments.sourceFundsDescription:A bank statement with visible income, tax declaration or any other document confirming yoyr incomes`, short: 'Some text'},
      type: Type.SourceOfFunds,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.SourceOfFunds, file: null },
      ]
    },
/*     {
      title: "Other",
      description: { full: "Some large Other doc description which contain very usssefulll information."},
      type: Type.Other,
      icon: "assets/icons/icon-upload-id.svg",
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.Other },
      ]
    }, */
  ]
};